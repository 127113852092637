<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary" class="padding-right-l padding-left-l">
            Additional Offer Information
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <div class="padding-l">
            <app-field-list classList="margin-top-0">
                <app-field-value label="Comments"><span [innerHTML]="offerInfoComment?.comments | default"></span></app-field-value>
            </app-field-list>

            <p class="color-primary bold">Checklist</p>
            <app-field-list classList="margin-top-0">
                <app-field-value label="Comments">{{ offerInfoChecklist?.comment | default }}</app-field-value>
                <app-field-value label="Status">{{ offerInfoChecklist?.status.description | default }}</app-field-value>
            </app-field-list>

            <ng-container *ngIf="offerInfoChecklist?.items?.length > 0">
                <ion-grid class="bare-table ion-no-padding">
                    <ion-row class="bare-table__thead">
                        <ion-col>
                            <strong>Item</strong>
                        </ion-col>
                        <ion-col size="3">
                            <strong>Status</strong>
                        </ion-col>
                        <ion-col size="2">
                            <strong>Status Date</strong>
                        </ion-col>
                    </ion-row>
                    <ng-container *ngFor="let item of offerInfoChecklist.items">
                        <ion-row class="bare-table__tbody ion-align-self-start">
                            <ion-col>{{ item.type.description | default }}</ion-col>
                            <ion-col size="3">{{ item.status.description | default }}</ion-col>
                            <ion-col size="2">{{ item.statusChangedOn | shortDate | default }}</ion-col>
                        </ion-row>
                    </ng-container>
                </ion-grid>
            </ng-container>
        </div>
    </app-dataload-spinner>

    <div class="container padding-right-l padding-left-l" *ngIf="!isLoading && !offerInfoChecklist">
        <p class="header__notfound ">No data to display</p>
    </div>
</ion-content>


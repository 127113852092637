<div class="margin-top-l margin-bottom-l" *ngIf="isShowMessage">
    <app-alert-banner type="info">
        <span class="bold" *ngIf="isShowSuspMsg">
            The candidate has currently suspended their enrolment to {{ latestAbsence.to | shortDate }}
        </span>
        <span class="bold" *ngIf="isShowExtnMsg">
            The candidate has been granted an extension from {{ latestAbsence.from | shortDate }} to {{ latestAbsence.to | shortDate }}
        </span>
        <span class="bold" *ngIf="isShowAbsentMsg">
            The candidate has a leave of absence until {{ latestAbsence.to | shortDate }}
        </span>
    </app-alert-banner>
</div>
<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="2">
                Acad Programme
            </ion-col>
            <ion-col size="">
                Description
            </ion-col>
            <ion-col size="">
                Academic Plan
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                Start Date
            </ion-col>
            <ion-col size="2"  class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="research?.length > 0; else empty">
            <ng-container *ngFor="let researchInfo of research; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ researchInfo.programme?.code | default }}</ion-col>
                    <ion-col size="">{{ researchInfo.programme?.description | default }}</ion-col>
                    <ion-col size="">
                        <ng-container *ngIf="researchInfo.plans?.length > 0; else noPlans">
                            <ng-container  *ngFor="let plan of researchInfo.plans">
                                {{ plan.plan.code }} - {{ plan.plan.description }} <br/>
                            </ng-container>
                        </ng-container>
                        <ng-template #noPlans>
                            No data
                        </ng-template>
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">{{ researchInfo.start | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-research-information-details [highlight]="isEven" [details]="researchInfo" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #empty>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>
<h3 class="color-primary">Leave</h3>
<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="">
                Acad Programme
            </ion-col>
            <ion-col size="">
                Leave Code
            </ion-col>
            <ion-col size="">
                Description
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                From Date
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                To Date
            </ion-col>
            <ion-col size="2">
                Absence Duration Days
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">
        <ng-container *ngIf="absences?.length > 0; else noAbsences">
            <ng-container *ngFor="let absence of absences;">
                <ion-row>
                    <ion-col size="">{{ absence.programme?.code | default }}</ion-col>
                    <ion-col size="">{{ absence.type.code | default }}</ion-col>
                    <ion-col size="">{{ absence.type.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ absence.from | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ absence.to | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ absence.duration | default }}</ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #noAbsences>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>
    </div>
</ion-grid>
import { Component, Input } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import {
  ScholarshipApplicationDetailField,
  ScholarshipApplicationDetailViewModel,
  ScholarshipApplicationViewModel
} from '../scholarship-applications-view.model';
import { ScholarshipApplicationDetail, ServiceError } from '@domain/models';

@Component({
  selector: 'app-scholarship-application-details',
  templateUrl: './scholarship-application-details.component.html',
  styleUrl: './scholarship-application-details.component.scss',
})
export class ScholarshipApplicationDetailsComponent {
  isLoading: boolean;
  isError: boolean;
  message: string;
  details: ScholarshipApplicationDetailViewModel[];

  constructor(private student: StudentAdapter) { }

  @Input() application: ScholarshipApplicationViewModel;
  @Input() set isOpen(value: boolean) {
    if (value) {
      this.load();
    }
  }

  public load(): void {
    this.isError = false;
    this.isLoading = true;

    this.student.getScholarshipsApplicationDetails(this.application.studentId, this.application.id)
      .subscribe({
        next: (result) => {
          this.details = this.buildViewModel(result);
          this.isLoading = false;
        },
        error: (error) => {
          if (error instanceof ServiceError) {
            this.message = error.message;
          }
          this.isError = true;
          this.isLoading = false;
        },
      });
  }

  buildViewModel(result: ScholarshipApplicationDetail): ScholarshipApplicationDetailViewModel[] {
    if (result && Object.keys(result).length > 0) {
      return Object.entries(result)
        .filter(([section]) => section != 'PersonalDetails')
        .map(([section, fields]) => this.mapSection(section, fields));
    }
    return [];
  }

  private mapSection(section: string, fields: { [key: string]: string }): ScholarshipApplicationDetailViewModel {
    return {
      section: this.unCamelCase(section),
      fields: this.mapSectionFields(fields)
    };
  }

  private mapSectionFields(fields: { [key: string]: string }): ScholarshipApplicationDetailField[] {
    return Object.entries(fields)
      .sort(([fieldNameA], [fieldNameB]) => fieldNameA.localeCompare(fieldNameB))
      .filter(([fieldName]) => fieldName != 'RowID')
      .map(([fieldName, value]) => ({
        fieldName: this.unCamelCase(fieldName),
        value
      }));
  }

  private unCamelCase(str: string): string {
    return str
      // replace UoA with UOA to prevent it being split as 'Uo A'
      .replace(/UoA/g, 'UOA ')
      // puts space after the last uppercase letter of the sequence
      .replace(/([A-Z])([a-z])([A-Z])([A-Z]+)/g, '$1$2$3 $4')
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // replace underscores with spaces
      .replaceAll('_', ' ')
      // seperate a block of numbers with a space from previous word
      .replace(/(?<=[^ 0-9])([0-9]+)/g, ' $1')
      // put a space at the end of a block of numbers
      .replace(/([0-9]+(?=\D))/g, '$1 ');
  }
}

import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { AppConfiguration } from '@domain/models';
import { APP_CONFIG } from '@core/providers';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';
import { ModalController } from '@ionic/angular';
import {
    OfferInformationModalComponent,
    ResearchApplicationModalComponent,
    ReferralModalComponent,
    IntendedCourseModalComponent
} from '@modules/person/components';
import dayjs from 'dayjs';
@Component({
  selector: 'app-submitted-application-details',
  templateUrl: './submitted-application-details.component.html',
  styleUrl: './submitted-application-details.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class SubmittedApplicationDetailsComponent {
    @Input() application: SubmittedApplicationsViewModel;
    @Input() highlight: boolean;

    static schoolLeaverAdmitTypes: Set<string> = new Set(['CIE', 'CIG', 'IBG', 'MCI', 'MNC', 'MSQ', 'NCE', 'NCG', 'IBN']);

    constructor(@Inject(APP_CONFIG) private configuration: AppConfiguration, private modalCtrl: ModalController) {
    }

    openAtsLink(externalApplicationNum: string) {
        // Matches only the number
        const matches = externalApplicationNum?.match( /\d+/g);

        if (matches && matches.length > 0) {
            const atsLink = `${this.configuration.links.ats}/read-only-view/${matches[0]}`;
            window.open(atsLink, '_blank');
        }
    }

    get applicationLink(): string {
        if (this.application === undefined) {
            return '#';
        }

        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.application}?Page=ADM_APPL_PROG_MNT&Action=U&ACAD_CAREER=${this.application.career.code}&ADM_APPL_NBR=${this.application.applicationNumber}&&APPL_PROG_NBR=0&EMPLID=${this.application.studentId}&INSTITUTION=UOFAK`;
    }

    get isGradePointEquivalentVisble(): boolean {
        if (this.application) {
            return !SubmittedApplicationDetailsComponent.schoolLeaverAdmitTypes.has(this.application.admitType.code);
        }

        return true;
    }

    /**
     * All ATS applications are prefixed with SDJ and are only visible for two years.
     * This is based on the ATS create date, which is approximately the same as the CS9 create date (but not exact),
    **/
    get isAtsVisible(): boolean {
        return this.application.externalApplicationNumber?.startsWith('SDJ') && dayjs().diff(this.application.createdOn, 'month') < 24;
    }

    get isConditionalOffer(): boolean {
        return this.application.status?.code === 'COND';
    }

    get isAdmitOffer(): boolean {
        return this.application.programme?.reason.action?.code === 'ADMT';
    }

    async openResearchAppModal() {
        await this.openModal(ResearchApplicationModalComponent, 'medium-modal');
    }

    async openReferralModal() {
        await this.openModal(ReferralModalComponent, 'medium-modal');
    }

    async openIntendedCoursesModal() {
        await this.openModal(IntendedCourseModalComponent, 'medium-modal');
    }

    async openOfferInfoModal() {
        await this.openModal(OfferInformationModalComponent, 'medium-modal');
    }

    private async openModal(component, cssClass) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                application: this.application,
            },
            component: component,
            cssClass: cssClass
        });

        void modal.present();
    }
}

<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="6">
                Scholarship Name
            </ion-col>
            <ion-col size="4">
                Status
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="applications?.length > 0; else empty">
            <ng-container *ngFor="let application of applications; even as isEven; index as i;">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="6">{{ application.scholarshipName | default }}</ion-col>
                    <ion-col size="4">{{ application.status | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-scholarship-application-details [isOpen]="isHideDetails(i)" [application]="application"></app-scholarship-application-details>
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>

        <ng-template #empty>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>
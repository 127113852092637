import { Component, Input, OnInit } from '@angular/core';
import { Checklist, Comment } from '@domain/models';
import { ModalController } from '@ionic/angular';
import { StudentAdapter } from '@core/adapters';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';

@Component({
  selector: 'app-offer-information-modal',
  templateUrl: './offer-information-modal.component.html',
  styleUrl: './offer-information-modal.component.scss'
})
export class OfferInformationModalComponent implements OnInit {
    isLoading = true;
    isError = false;
    offerInfoChecklist: Checklist;
    offerInfoComment: Comment;

    @Input() application: SubmittedApplicationsViewModel;

    constructor(private modalCtrl: ModalController, private studentAdapter: StudentAdapter) {}

    get isAdmitOffer(): boolean {
        return this.application.programme?.reason.action?.code === 'ADMT';
    }

    ngOnInit() {
        const type = this.isAdmitOffer ? 'admt' : 'cond';

        this.studentAdapter.getOfferInfo(type, this.application.studentId, this.application.applicationNumber)
            .subscribe({
                next: (result) => {
                  this.offerInfoChecklist = result.checklist;
                  this.offerInfoComment = result.comment;

                   this.isLoading = false;
                   this.isError = false;
                },
                error: () => {
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    close() {
        void this.modalCtrl.dismiss();
    }
}

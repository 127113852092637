import { Component, Input } from '@angular/core';
import { StudentProgramme } from '@domain/models';
import { ResearchAbsenceViewModel, ResearchInformationViewModel } from '../research-information-view.model';
import {sortByProp} from '@app/util';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

@Component({
  selector: 'app-research-information',
  templateUrl: './research-information.component.html',
  styleUrl: './research-information.component.scss'
})
export class ResearchInformationComponent {
    openPanels = new Set<number>();
    programme: StudentProgramme;
    isShowExtnMsg: boolean = false;
    isShowSuspMsg: boolean = false;
    isShowAbsentMsg: boolean = false;

    @Input() research: ResearchInformationViewModel[];

    get absences(): ResearchAbsenceViewModel[] {
        const absences = this.research.flatMap((v) =>
            v.absence.map(a => ({...a, programme: v.programme}))
        );

        return sortByProp('descend', absences, 'to');
    }

    get latestAbsence(): ResearchAbsenceViewModel {
        return this.absences[0];
    }

    get isShowMessage(): boolean {
        if (this.latestAbsence) {
            const today = dayjs();
            const { from, to, type: { code } } = this.latestAbsence;

            // Show message if date today is between the latest absence
            if (today.isBetween(dayjs(from), dayjs(to))) {
                if (code === 'EXTN') {
                    this.isShowExtnMsg = true;
                } else if (code === 'SUSP') {
                    this.isShowSuspMsg = true;
                } else if (code === 'ABSENT') {
                    this.isShowAbsentMsg = true;
                } else {
                    return false;
                }
                return true;
            }
        }

        return false;
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }
}

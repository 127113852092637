import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap, zip, catchError, throwError } from 'rxjs';
import {
    StudentChecklists,
    ExternalTest,
    PriorEducation,
    StudentId,
    EnglishLanguageProficiency,
    Residency,
    ServiceError,
    ServiceIndicator,
    StudentVisasInsurance,
    Group,
    UnsubmittedApplications,
    StudentProgress,
    Comment,
    PriorityGroup,
    ResearchApplication,
    StudyLink,
    EntranceQualificationAndComment,
    SubmittedApplications,
    StudyLinkLoan,
    Concession,
    StudentFees,
    ProgrammeCourses,
    ThirdPartyContract,
    Transcript,
    ExternalTestResult,
    Timetable,
    Communication,
    Exam,
    ApplicationReferral,
    TaxInvoice,
    ResearchProgramme,
    Degree,
    IntendedCourse,
    StudentCourseCredit,
    EnrolmentCart,
    ScholarshipPayment,
    GraduationProgramme,
    ScholarshipAwarded,
    CommentAndChecklist,
    ScholarshipApplication,
    ScholarshipApplicationDetail,
} from '@domain/models';
import { IdentityService, StudentService } from '@core/services';
import { isUpi, isIdentityId } from '@app/util/types';
import { DataResponse } from '../services/model';

/**
 * Retrieve and orchestrate information about students
 *
 */
@Injectable({
    providedIn: 'root',
})
export class StudentAdapter {
    constructor(
        private identity: IdentityService,
        private student: StudentService
    ) {
    }

    private readonly APPLICATIONS_CHECKLIST_ADMIN_FUNCTIONS = ['ADMA', 'ADMP', 'PROS', 'SPRG'];
    private readonly STUDENT_CHECKLIST_ADMIN_FUNCTIONS = ['GEN', 'SFAC', 'SPRG', 'STRM'];

    private readonly CHECKLIST_TYPE_CONDITIONAL_OFFER = 'CNDOFR';
    private readonly CHECKLIST_TYPE_ADDITIONAL_REQ = 'ADDREQ';
    private readonly COMMENT_CATEGORY_OFFER = 'OFRCMT';

    /**
     * Get filtered checklists and optionally linked applications
     *
     * Note, that only ADMP types have a direct linked application so we only retrieve
     * applications if there is at least one checklist with an application
     *
     * @param idOrUpi
     * @param filter
     * @returns
     */
    private getChecklists(idOrUpi: StudentId, filter: string[]): Observable<StudentChecklists> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => this.student.getChecklists(id).pipe(
                        map(checklists => checklists.filter(v => filter.includes(v.adminFunction.code))),
                        switchMap(checklist => {
                            // If there are linked applications then retrieve
                            if (checklist.some(chk => chk.admissionApplication)) {
                                return this.student.getSubmittedApplications(id)
                                        .pipe(
                                            map(submitted => ({checklist, submitted}))
                                        );
                            }

                            return of({checklist, submitted: []});
                        })
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student admission checklists', err)) )
            );
    }

    /**
     * Get student application checklists by student id or UPI
     *
     * @param idOrUpi student id or upi
     *
     * @returns List of Admissions Checklists
     */
    public getApplicationsChecklists(idOrUpi: StudentId): Observable<StudentChecklists> {
        return this.getChecklists(idOrUpi, this.APPLICATIONS_CHECKLIST_ADMIN_FUNCTIONS);
    }

    /**
     * Get student checklists by student id or UPI
     *
     * @param idOrUpi student id or upi
     *
     * @returns List of Student Checklists
     */
    public getStudentChecklists(idOrUpi: StudentId): Observable<StudentChecklists> {
        return this.getChecklists(idOrUpi, this.STUDENT_CHECKLIST_ADMIN_FUNCTIONS);
    }

    public getOfferInfo(offerType: 'admt' | 'cond', idOrUpi: StudentId, applicationNumber: string): Observable<CommentAndChecklist> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getChecklists(id).pipe(
                            map(checklists => checklists.find(v => {
                                const type = offerType === 'cond' ?
                                    this.CHECKLIST_TYPE_CONDITIONAL_OFFER : this.CHECKLIST_TYPE_ADDITIONAL_REQ;

                                return v.type.code === type && v.admissionApplication == applicationNumber;
                            })),
                        ),
                        this.getApplicationsComments(id).pipe(
                            map(comments =>
                                comments.toSorted((a, b) => b.commentDate.getTime() - a.commentDate.getTime())
                                .find(v => v.category?.code === this.COMMENT_CATEGORY_OFFER && v.applicationNumber == applicationNumber))
                        )
                    ).pipe(map(([checklist, comment]) => ({checklist, comment})))
                ),
                catchError((err) => throwError( () =>
                    new ServiceError('Sorry, there was an unexpected problem displaying student admission checklists', err)))
            );
    }

    public getApplicationsEntranceQualAndComments(idOrUpi: StudentId): Observable<EntranceQualificationAndComment> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getEntranceEducation(id),
                        this.getApplicationsComments(id)
                    ).pipe(
                        map(([entranceQualification, comments]) => ({
                            entranceQualification, comments
                    }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student checklists', err)) )
            );
    }


    private APPLICATIONS_COMMENTS_ADMIN_FUNCTIONS = ['ADMA', 'ADMP'];

    private getApplicationsComments(idOrUpi: StudentId): Observable<Comment[]> {
       return this.getComments(idOrUpi,
            (comment => this.APPLICATIONS_COMMENTS_ADMIN_FUNCTIONS.includes(comment.adminFunction.code)));
    }

    public getStudentComments(idOrUpi: StudentId): Observable<Comment[]> {
        return this.getComments(idOrUpi,
             (comment => !this.APPLICATIONS_COMMENTS_ADMIN_FUNCTIONS.includes(comment.adminFunction.code)));
    }

    private getComments(idOrUpi: StudentId, filterFunction: (a: Comment)=> boolean): Observable<Comment[]> {
        return this.getStudentId(idOrUpi)
        .pipe(
            switchMap(
                id => this.student.getComments(id)
                .pipe(
                    map(comments => comments.filter(filterFunction))
                    )
            ),
            catchError((err) => throwError( () =>
                new ServiceError('Sorry, there was an unexpected problem displaying comments', err)))
        );
    }

    /**
     * Get student external tests (NZ secondary schools) by student id or UPI.
     * Information may be embargoed at specific times of the year
     *
     * @param idOrUpi student id or upi
     *
     * @returns List of ExternalTest
     */
    public getExternalTests(idOrUpi: StudentId): Observable<ExternalTest[]> {
        return this.getStudentId(idOrUpi)
                .pipe(
                    switchMap(id => this.student.getExternalTests(id)),
                    catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student external tests', err)) )
                );
    }


    /**
     * Fetch student external test results by student id and test id.
     *
     * @param id student id
     * @param testId test id
     *
     * @returns List of ExternalTestResult
     */
    public getExternalTestResults(id: string, testId: string): Observable<ExternalTestResult[]> {
        return this.student.getExternalTestResults(id, testId)
            .pipe(
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student external test results', err)) )
            );
    }

    /**
     * Get an aggregation of prior education for:
     *
     * - Secondary
     * - Tertiary
     * - Foundation
     *
     * @param idOrUpi
     * @returns PriorEducation
     */
    public getPriorEducation(idOrUpi: StudentId): Observable<PriorEducation> {
        return this.getStudentId(idOrUpi)
                .pipe(
                    switchMap(
                        // Fetch all education in parallel and map to prior education aggregation
                        (id) => zip(
                            this.student.getSecondaryEducation(id),
                            this.student.getTertiaryEducation(id),
                            this.student.getFoundationEducation(id)
                        )
                        .pipe(
                            map(([ secondary, tertiary, foundation]) => ({
                                secondary: secondary ? secondary.sort((a, b) => b.academicYear - a.academicYear) : [],
                                tertiary: tertiary ? tertiary.sort((a, b) => a.end.getTime() - b.end.getTime()) : [],
                                foundation : foundation ? foundation.sort( (a, b) => a.endYear - b.endYear) : []
                            })
                        ))
                    ),
                    catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student prior education', err)) )
                );
    }

    /**
     * Get a list of submitted and research applications
     *
     * @param idOrUpi
     * @returns List of SubmittedApplication[] and ResearchApplication[]
     */
    public getSubmittedApplications(idOrUpi: StudentId): Observable<SubmittedApplications> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => zip(
                        this.student.getSubmittedApplications(id),
                        this.student.getResearchApplications(id)
                    ).pipe(
                        map(([submitted, research]) => ({submitted, research}))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student applications', err)) ),
            );
    }

    /**
     * Get a list of unsubmitted applications
     *
     * @param idOrUpi
     * @returns List of UnsubmittedApplications
     */
    public getUnsubmittedApplications(idOrUpi: StudentId): Observable<UnsubmittedApplications> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getUnsubmittedApplications(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student applications', err)) ),
            );
    }

    /**
     * Get a list of student service indicators
     *
     * @param idOrUpi
     * @returns List of ServiceIndicator
     */
    public getServiceIndicators(idOrUpi: StudentId): Observable<ServiceIndicator[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getServiceIndicators(id)),
                catchError((err) => throwError(() => new ServiceError('Unable to retrieve student service indicators', err)))
            );
    }

    /**
     * Get an data required for displaying the English Language Proficency page
     *
     * - Education Backround
     * - English Language Tests
     * - in future, DELNA
     * @param idOrUpi
     * @returns EnglishLanguageProficency
     */
    public getEnglishLanguageProficiency(idOrUpi: StudentId): Observable<EnglishLanguageProficiency> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    // Fetch all education in parallel and map to prior education aggregation
                    (id) => zip(
                        this.student.getEducationBackground(id),
                        this.student.getEnglishEducation(id)
                    )
                        .pipe(
                            map(([educationBackground, englishLanguageEducation]) => ({
                                    educationBackground, englishLanguageEducation
                                })
                            ))
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student language proficiency', err)) )
            );
    }

    /**
     * Get student residency data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @returns List of Residency
     */
    public getResidency(idOrUpi: StudentId): Observable<Residency[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getResidency(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student residency', err)) )
            );
    }

    public getGroups(idOrUpi: StudentId): Observable<Group[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getGroups(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student groups', err)) )
            );
    }

    /**
     * Get student visas and insurance by student id or UPI
     *
     * @param idOrUpi student id or upi
     *
     * @returns List of Visa and Insurance
     */
    public getStudentVisasInsurance(idOrUpi: StudentId): Observable<StudentVisasInsurance> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getVisas(id),
                        this.student.getInsurance(id)
                    ).pipe(
                        map(([visas, insurance]) => ({
                            visas, insurance
                        }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student visas and insurance', err)) )
            );
    }

    /**
     * Get an aggregation of student progress for:
     *
     * - AELR
     * - Academic Standing
     * - DELNA
     * - Student progress
     *
     * @param idOrUpi
     * @returns StudentProgress
     */
    public getStudentProgress(idOrUpi: StudentId): Observable<StudentProgress> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getAELR(id),
                        //this.student.getAcademicStanding(id),
                        of([]),
                        this.student.getDelna(id),
                        this.student.getStudentProgress(id)
                    ).pipe(
                        map(([aelr, academicStanding, delna, progress]) => ({
                            aelr, academicStanding, delna, progress
                        }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student progress', err)) )
            );
    }

    /**
     * Get priority groups data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return PriorityGroup[] - List of priority groups
     */
    public getPriorityGroups(idOrUpi: StudentId): Observable<PriorityGroup[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getPriorityGroups(id)),
                catchError((err) => {
                    if (err.error.statusCode === 404) {
                        return of([]);
                    } else {
                        throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying priority groups', err));
                    }
                }
            ));
    }

    /**
     * Get research applications data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @returns List of ResearchApplications
     */
    public getResearchApplications(idOrUpi: StudentId): Observable<ResearchApplication[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getResearchApplications(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying research applications', err)) )
            );
    }

    /**
     * Get study link data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return StudyLink[] - List of study link
     */
    public getStudyLink(idOrUpi: StudentId): Observable<StudyLink[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getStudyLink(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying study link', err)) )
            );
    }

    /**
     * Get study link loan data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return StudyLinkLoan[] - List of study link loans
     */
    public getStudyLinkLoans(idOrUpi: StudentId): Observable<StudyLinkLoan[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getStudyLinkLoans(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying study link loans', err)) )
            );
    }

    /**
     * Get tuition fees data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return TuitionFees
     */
    public getStudentFees(idOrUpi: StudentId): Observable<StudentFees> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getStudentTuitionFees(id),
                        this.student.getServiceIndicators(id)
                    ).pipe(
                        map(([fees, indicators]) => ({
                            fees,
                            feesFree: indicators.find(ind => ind.indicator.code === 'FFE')
                        }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying tuition fees', err)) )
            );
    }

    /**
     * Get concessions data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return Concession[] - List of concessions
     */
    public getStudentConcessions(idOrUpi: StudentId): Observable<Concession[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getStudentConcessions(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying concessions', err)) )
            );
    }

    /**
     * Get student programmes and courses data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return ProgrammeCourses - List of programmes, courses, and student term progress
     */
    public getProgrammeCourses(idOrUpi: StudentId): Observable<ProgrammeCourses> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getStudentProgrammes(id),
                        this.student.getCourses(id),
                        this.student.getTermProgress(id)
                    ).pipe(
                        map(([programmes, courses, termProgress]) => ({
                            programmes, courses, termProgress
                        }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student programme and courses', err)) )
            );
    }

    /**
     * Get student third party contracts data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return ThirdPartyContract[] - List of third party contracts
     */
    public getThirdPartyContracts(idOrUpi: StudentId): Observable<ThirdPartyContract[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getThirdPartyContracts(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying third party contracts', err)) )
            );
    }

    /**
     * Get transcript data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return Transcript[] - List of transcripts
     */
    public getTranscripts(idOrUpi: StudentId): Observable<Transcript[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getTranscripts(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying your transcripts', err)) )
            );
    }

    /**
     * Get communications data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return DataResponse<Communication> - Communications data
     */
    public getCommunications(idOrUpi: StudentId): Observable<DataResponse<Communication>> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getCommunications(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying communications', err)) )
            );
    }

    /**
     * Get communications data by URL.
     *
     * @param url Link to communications
     *
     * @return DataResponse<Communication> - Communications data
     */
    public getCommunicationsByUrl(url: string): Observable<DataResponse<Communication>> {
        return this.student.getCommunicationsByUrl(url)
            .pipe(
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying communications', err)) )
            );
    }

    /**
     * Get timetables data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     * @param academicYear academic year
     *
     * @return Timetable[] - List of timetable
     */
    public getTimetables(idOrUpi: StudentId, academicYear?: number): Observable<Timetable[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getTimetables(id, academicYear, academicYear)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying timetables', err)) )
            );
    }

    /**
     * Get exams data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return Exam[] - List of exams
     */
    public getExams(idOrUpi: StudentId): Observable<Exam> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getExams(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying exams', err)) )
            );
    }

    /**
     * Get referral information by student id and application number
     *
     * @param id student id or upi
     * @param appNo application number
     *
     * @return ApplicationReferral
     */
    public getApplicationReferrals(id: string, appNo: string): Observable<ApplicationReferral> {
        return this.student.getApplicationReferrals(id, appNo)
            .pipe(
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying referrals', err)) )
            );
    }

    /**
     * Get invoices data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return TaxInvoice - List of invoices
     */
    public getInvoices(idOrUpi: StudentId): Observable<TaxInvoice> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getInvoices(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying invoices', err)) )
            );
    }

    /**
     * Get graduation data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return GraduationProgramme - List of Graduation and Student Programme information
     */
    public getGraduationInfo(idOrUpi: StudentId): Observable<GraduationProgramme> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getGraduationInfo(id),
                        this.student.getStudentProgrammes(id),
                    ).pipe(
                        map(([graduation, programmes]) => ({
                            graduation, programmes
                        }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying graduation information', err)) )
            );
    }

    /**
     * Get research and programme information by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return ResearchProgramme - List of Research and Programme information
     */
    public getResearchProgramme(idOrUpi: StudentId): Observable<ResearchProgramme> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => zip(
                        this.student.getResearchInfo(id),
                        this.student.getStudentProgrammes(id)
                    ).pipe(
                        map(([research, programmes]) => ({
                            research, programmes
                        }))
                    )
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student research and programme', err)) )
            );
    }

    /**
     * Get degree data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return Degree[] - List of Degree information
     */
    public getDegrees(idOrUpi: StudentId): Observable<Degree[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getDegrees(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying degree information', err)) )
            );
    }

    public getIntendedCourses(id: string, appNo: string): Observable<IntendedCourse[]> {
        return this.student.getIntendedCourses(id, appNo)
            .pipe(
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying intended courses', err)) )
            );
    }

    /**
     * Get course credit data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return StudentCourseCredit[] - List of Course Credit information
     */
    public getCourseCredit(idOrUpi: StudentId): Observable<StudentCourseCredit[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getCourseCredit(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying course credit information', err)) )
            );
    }

    /**
     * Get enrolment cart data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return EnrolmentCart[] - List of Enrolment Cart information
     */
    public getEnrolmentCart(idOrUpi: StudentId): Observable<EnrolmentCart[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getEnrolmentCart(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying enrolment cart information', err)) )
            );
    }

    /**
     * Get scholarships awarded history by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return ScholarshipAwarded - List of scholarship awarded
     */
    public getScholarshipsHistory(idOrUpi: StudentId): Observable<ScholarshipAwarded[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(
                    id => this.student.getScholarshipsAwarded(id)
                ),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying awarded scholarships', err)) )
            );
        }

    /**
     * Get scholarship payments data by student id or UPI.
     *
     * @param idOrUpi student id or upi
     *
     * @return ScholarshipPayments[] - List of Scholarship Payments information
     */
    public getScholarshipsPayments(idOrUpi: StudentId): Observable<DataResponse<ScholarshipPayment>> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getScholarshipsPayments(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying scholarship payments information', err)) )
            );
    }

    /**
     * Fetches the scholarship payments pages from the provided URL.
     * Retrieves a paginated response containing scholarship payment data.
     *
     * @param {string} url - The URL to fetch scholarship payments from.
     * @return {Observable<DataResponse<ScholarshipPayment>>} An observable emitting the data response containing the scholarship payment pages.
     */
    public getScholarshipsPaymentsPages(url: string): Observable<DataResponse<ScholarshipPayment>> {
        return this.student.getScholarshipPaymentsPages(url)
            .pipe(
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying scholarship payments information', err)) )
            );
    }

    /**
     * Retrieves the scholarship applications for a student based on their ID or UPI.
     *
     * @param {StudentId} idOrUpi - student id or upi
     *
     * @return ScholarshipApplication[] - List of Scholarships Applications information
     */
    public getScholarshipsApplications(idOrUpi: StudentId): Observable<ScholarshipApplication[]> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getScholarshipsApplications(id)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying scholarship applications information', err)) )
            );
    }

    /**
     * Retrieves the scholarship application details for a student based on their ID or UPI and application ID.
     *
     * @param {StudentId} idOrUpi - student id or upi
     * @param {string} applicationId - Scholarship application ID
     *
     * @return ScholarshipApplicationDetail - List of Scholarships Application details information
     */
    public getScholarshipsApplicationDetails(idOrUpi: StudentId, applicationId: string): Observable<ScholarshipApplicationDetail> {
        return this.getStudentId(idOrUpi)
            .pipe(
                switchMap(id => this.student.getScholarshipsApplicationDetails(id, applicationId)),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying scholarship application details information', err)) )
            );
    }

    private getStudentId(idOrUpi: StudentId): Observable<string> {
        if (typeof idOrUpi === 'number') {
            return of(`${idOrUpi}`);
        }

        if (isUpi(idOrUpi)) {
            return this.identity.getById(idOrUpi)
                    .pipe(
                        map(identity => `${identity.id}`)
                    );
        } else if (isIdentityId(idOrUpi)) {
            return of(idOrUpi);
        }

        throw new Error(`Student identifier ${idOrUpi} must be a upi or id`);
    }
}

export const StudentAdapterProvider={ provide: StudentAdapter };